
import Vue from 'vue'
import NotificationsManager from '@/components/NotificationsManager.vue'
import { readKwikIsLoggedIn } from 'kwik-vue/store/main/getters'
import { dispatchKwikCheckLoggedIn, dispatchKwikSwitchTheme } from 'kwik-vue/store/main/actions'
import { getCurrentLocale, getSelectedTheme } from '@/utils/localStorage'
import { ThemeSelector } from '@/interfaces/theme'

export default Vue.extend({
  name: 'App',
  components: {
    NotificationsManager
  },
  computed: {
    loggedIn () {
      return readKwikIsLoggedIn(this.$store)
    }
  },
  beforeCreate () {
    const currentLocale = getCurrentLocale()
    if (currentLocale) {
      this.$i18n.locale = currentLocale
    }
    const storedTheme = getSelectedTheme()
    if (storedTheme === ThemeSelector.LIGHT) {
      dispatchKwikSwitchTheme(this.$store, ThemeSelector.LIGHT)
      this.$vuetify.theme.dark = false
    } else {
      dispatchKwikSwitchTheme(this.$store, ThemeSelector.DARK)
      this.$vuetify.theme.dark = true
    }
  },
  async created () {
    await dispatchKwikCheckLoggedIn(this.$store)
  }
})
