import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import trackingNotesRepository from '../repository'
import { TrackingNoteState } from './state'
import { commitSetTrackingNotesCurrentPage } from './mutations'
import { ITrackingNoteCreate, ITrackingNotesGetInput } from '../types'

type MainContext = ActionContext<TrackingNoteState, RootState>;

export const actions = {
  async actionGetTrackingNotesCurrentPage (context: MainContext, payload: ITrackingNotesGetInput) {
    try {
      const response = await trackingNotesRepository.getAll(payload)
      if (response) {
        commitSetTrackingNotesCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionCreateTrackingNotes (context: MainContext, payload: ITrackingNoteCreate) {
    try {
      const response = await trackingNotesRepository.create(payload)
      return response.data
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionDeleteTrackingNotes (context: MainContext, id: number) {
    try {
      const response = await trackingNotesRepository.delete(id)
      return response.data
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<TrackingNoteState | any, RootState>('')

export const dispatchGetTrackingNotesCurrentPage = dispatch(actions.actionGetTrackingNotesCurrentPage)
export const dispatchCreateTrackingNotes = dispatch(actions.actionCreateTrackingNotes)
export const dispatchDeleteTrackingNotes = dispatch(actions.actionDeleteTrackingNotes)
