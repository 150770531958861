import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { StatsState } from './state'

import { IStats } from '../types'

export const mutations = {
  setLastStats (state: StatsState, payload: IStats) {
    state.lastStats = payload
  }
}

const { commit } = getStoreAccessors<StatsState | any, RootState>('')
export const commitSetLastStats = commit(mutations.setLastStats)
