import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { ProductionOrdersState } from './state'
import { IProductionOrders } from '../types'

export const mutations = {
  setProductionOrdersCurrentPage (state: ProductionOrdersState, payload: IPaginatedResult<IProductionOrders>) {
    state.productionOrdersCurrentPage = payload.data
    state.productionOrdersCount = payload.total
  }
}

const { commit } = getStoreAccessors<ProductionOrdersState | any, RootState>('')
export const commitSetProductionOrdersCurrentPage = commit(mutations.setProductionOrdersCurrentPage)
