import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import priceListsRepository from '../repository'
import { PriceListState } from './state'
import { commitSetPriceListsCurrentPage } from './mutations'
import { IPriceListGetInput } from '../types'

type MainContext = ActionContext<PriceListState, RootState>;

export const actions = {
  async actionGetPriceListsCurrentPage (context: MainContext, payload: IPriceListGetInput) {
    try {
      const response = await priceListsRepository.getAll(payload)
      if (response) {
        commitSetPriceListsCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<PriceListState | any, RootState>('')

export const dispatchGetPriceListsCurrentPage = dispatch(actions.actionGetPriceListsCurrentPage)
