import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import { KwikMainState } from 'kwik-vue/store/main/state'
import { KwikPermissionState } from 'kwik-vue/store/permission/state'
import { KwikCurrentUserState } from 'kwik-vue/store/currentUser/state'
import { AuthState } from '@/store/auth/state'
import { WarehouseState } from '@/modules/warehouse/store/state'
import { BankingState } from '@/modules/banking/store/state'
import { CompanyMachineState } from '@/modules/company_machines/store/state'
import { SupplierTransactionState } from '@/modules/supplier_transaction/store/state'
import { PriceListState } from '@/modules/price_list/store/state'
import { OfferingState } from '@/modules/offering/store/state'
import { CustomerTransactionState } from '@/modules/customer_transaction/store/state'
import { ProductionOrdersState } from '@/modules/production_orders/store/state'
import { CustomerRequestsState } from '@/modules/customer_requests/store/state'
import { MatchState } from '@/modules/match/store/state'
import { BillOfMaterialsState } from '@/modules/bill_of_materials/store/state'
import { StatsState } from '@/modules/dashboard/store/state'

import { mainModule } from 'kwik-vue/store/main'
import { permissionModule } from 'kwik-vue/store/permission'
import { currentUserModule } from 'kwik-vue/store/currentUser'
import { authModule } from '@/store/auth'
import { warehouseModule } from '@/modules/warehouse/store'
import { bankingModule } from '@/modules/banking/store'
import { companyMachineModule } from '@/modules/company_machines/store'
import { supplierTransactionsModule } from '@/modules/supplier_transaction/store'
import { priceListsModule } from '@/modules/price_list/store'
import { offeringsModule } from '@/modules/offering/store'
import { customerTransactionsModule } from '@/modules/customer_transaction/store'
import { productionOrdersModule } from '@/modules/production_orders/store'
import { customerRequestsModule } from '@/modules/customer_requests/store'
import { matchModule } from '@/modules/match/store'
import { billOfMaterialsModule } from '@/modules/bill_of_materials/store'
import { TrackingNoteState } from '@/modules/tracking_notes/store/state'
import { trackingNotesModule } from '@/modules/tracking_notes/store'
import { NotificationState } from '@/modules/notifications/store/state'
import { notificationsModule } from '@/modules/notifications/store'
import { statsModule } from '@/modules/dashboard/store'

export interface RootState
{
  main: KwikMainState
  permission: KwikPermissionState,
  currentUser: KwikCurrentUserState,
  auth: AuthState,
  warehouseState: WarehouseState,
  bankingState: BankingState,
  companyMachineState: CompanyMachineState,
  supplierTransactionsState: SupplierTransactionState,
  priceListsState: PriceListState,
  offeringsState: OfferingState,
  productionOrdersState: ProductionOrdersState,
  customerTransactionsState: CustomerTransactionState,
  customerRequestsState: CustomerRequestsState,
  matchState: MatchState,
  billOfMaterialsState: BillOfMaterialsState,
  trackingNotesState: TrackingNoteState,
  notificationsState: NotificationState,
  statsState: StatsState
}

Vue.use(Vuex)

const storeOptions: StoreOptions<RootState | any> = {
  modules: {
    main: mainModule,
    permission: permissionModule,
    currentUser: currentUserModule,
    authModule: authModule,
    warehouseModule: warehouseModule,
    bankingModule: bankingModule,
    companyMachineModule: companyMachineModule,
    supplierTransactionsModule: supplierTransactionsModule,
    priceListsModule: priceListsModule,
    offeringsModule: offeringsModule,
    productionOrdersModule: productionOrdersModule,
    customerTransactionsModule: customerTransactionsModule,
    customerRequestsModule: customerRequestsModule,
    matchModule: matchModule,
    billOfMaterialsModule: billOfMaterialsModule,
    trackingNoteModule: trackingNotesModule,
    notificationsModule: notificationsModule,
    statsModule: statsModule
  }
}

export const store = new Vuex.Store<RootState>(storeOptions)

export default store
