import repository from '@/repositories/appAxios'

const resource = '/company/machines/'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll () {
    return repository.get(`${resource}`, config)
  },
  buyMachine (companyMachineId: number) {
    return repository.put(`${resource}buy?company_shop_machine_id=${companyMachineId}`, {}, config)
  },
  getShopCleaning (companyShopId: number) {
    return repository.get(`${resource}shop_cleaning?company_shop_id=${companyShopId}`, config)
  },
  getShopSetup (companyShopId: number) {
    return repository.get(`${resource}shop_setup?company_shop_id=${companyShopId}`, config)
  }
}
