import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { SupplierTransactionState } from './state'
import { ISupplierTransaction } from '../types'

export const mutations = {
  setSupplierTranactionsCurrentPage (state: SupplierTransactionState, payload: IPaginatedResult<ISupplierTransaction>) {
    state.supplierTransactionsCurrentPage = payload.data
    state.supplierTransactionsCount = payload.total
  }
}

const { commit } = getStoreAccessors<SupplierTransactionState | any, RootState>('')
export const commitSetSupplierTranactionsCurrentPage = commit(mutations.setSupplierTranactionsCurrentPage)
