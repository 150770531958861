import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { ICustomerRequest } from '../types'
import { CustomerRequestsState } from './state'

export const mutations = {
  setCustomerRequests (state: CustomerRequestsState, payload: ICustomerRequest) {
    state.customerRequests = payload
  }
}

const { commit } = getStoreAccessors<CustomerRequestsState | any, RootState>('')
export const commitSetCustomerRequests = commit(mutations.setCustomerRequests)
