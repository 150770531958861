export enum FilterTypes {
  STRING,
  CUSTOMER,
  SUPPLIER,
  SUPPLIER_TRANSACTION_STATE,
  PRODUCTION_TRANSACTION_STATE,
  CUSTOMER_TRANSACTION_STATE,
  OFFERING_STATE,
  DUE_DATE,
  MATERIAL,
  RAW_MATERIAL,
  BOOLEAN,
  WAREHOUSE_MODEL,
  MACHINE
}
