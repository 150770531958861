import { RouteNames } from '@/router/names'

export const CustomerRequestsRoute = {
  path: 'customer_requests',
  component: () => import(/* webpackChunkName: "customer_requests" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_CUSTOMER_REQUESTS,
      component: () => import(/* webpackChunkName: "customer_requests" */ '@/modules/customer_requests/views/FFCustomerRequests.vue')
    }
  ]
}
