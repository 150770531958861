import { RouteNames } from '@/router/names'

export const DashboardRoute = {
  path: 'dashboard',
  component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_DASHBOARD,
      component: () => import(/* webpackChunkName: "dashboard" */ '@/modules/dashboard/views/FFDashBoard.vue')
    }
  ]
}
