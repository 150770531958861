import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { CustomerRequestsState } from './state'

export const getters = {
  customerRequests: (state: CustomerRequestsState) => state.customerRequests
}

const { read } = getStoreAccessors<CustomerRequestsState, RootState>('')

export const readCustomerRequests = read(getters.customerRequests)
