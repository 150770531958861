import repository from '@/repositories/appAxios'
import { composePaginationQuery } from '@/utils/pagination'
import { IOfferingGetInput, IOfferPayload } from '../types'

const resource = 'company/offerings'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: IOfferingGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    if (getInput.customer_id) filterQuery += `&customer_id=${encodeURIComponent(getInput.customer_id)}`
    if (getInput.status) filterQuery += `&status=${encodeURIComponent(getInput.status)}`
    return repository.get(`${resource}?${pagingQuery}${filterQuery}`, config)
  },
  offer (payload: IOfferPayload) {
    return repository.post('company/offer', payload, config)
  }
}
