export enum RouteNames {
  // PUBLIC
  ABOUT = 'about',

  // SHARED
  FF_WAREHOUSE = 'ff-warehouse',
  FF_WAREHOUSE_MODELS = 'ff-warehouse-models',
  FF_BATCHES = 'ff-batches',
  FF_BANKING = 'ff-banking',
  FF_BOM = 'ff-bom',
  FF_BOM_DETAIL = 'ff-bom-detail',

  // INBOUND
  FF_NEW_SUPPLIER_TRANSACTIONS = 'ff-new-supplier-transactions',
  FF_SUPPLIER_TRANSACTIONS = 'ff-supplier-transactions',
  FF_SUPPLIER_TRANSACTION_ROWS = 'ff-supplier-transaction-rows',

  FF_PRICE_LISTS = 'ff-price-lists',
  FF_OFFERINGS = 'ff-offerings',

  // PRODUCTION
  FF_CREATE_ODP = 'ff-create-odp',
  FF_SCHEDULER = 'ff-scheduler',
  FF_MACHINES = 'ff-machines',

  // OUTBOUND
  FF_CUSTOMER_REQUESTS = 'ff-customer-requests',
  FF_OFFERS = 'ff-offers',
  FF_CUSTOMER_TRANSACTIONS = 'ff-customer-transactions',
  FF_CUSTOMER_TRANSACTION_ROWS = 'ff-customer-transaction-rows',
  FF_PRODUCTION_ORDERS = 'ff-production-orders',
  FF_PRODUCTION_ORDERS_ROWS = 'ff-production-orders-rows',
  FF_NEW_CUSTOMER_TRANSACTION = 'ff-new-customer-transactions',

  FF_DASHBOARD = 'ff-dashboard'
}
