export const commons = {
  buttons: {
    create: 'Create',
    add: 'Add',
    insert: 'Insert',
    save: 'Save',
    cancel: 'Cancel',
    reset: 'Reset',
    edit: 'Edit',
    delete: 'Delete',
    filter: 'Filter',
    filterTooltip: 'Allow to set filters',
    actions: 'Actions',
    confirm: 'Confirm',
    download: 'Download',
    downloadCSV: 'Download .csv',
    downloadXLSX: 'Download .xlsx'
  },
  dialogs: {
    confirmationRequired: 'Confirmation Required'
  },
  hours: 'hours'
}
