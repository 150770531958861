import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { NotificationState } from './state'

const defaultState: NotificationState = {
  notificationsCount: 0,
  notificationsCurrentPage: []
}

export const notificationsModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
