import { RouteNames } from '@/router/names'

export const BankingRoute = {
  path: 'banking',
  component: () => import(/* webpackChunkName: "warehouse" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_BANKING,
      component: () => import(/* webpackChunkName: "warehouse" */ '@/modules/banking/views/FFBanking.vue')
    }
  ]
}
