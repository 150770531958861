import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { StatsState } from './state'

const defaultState: StatsState = {
  lastStats: null
}

export const statsModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
