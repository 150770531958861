import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { CompanyMachineState } from './state'

const defaultState: CompanyMachineState = {
  companyMachines: []
}

export const companyMachineModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
