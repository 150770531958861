import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { SupplierTransactionState } from './state'

export const getters = {
  supplierTransactionsCurrentPage: (state: SupplierTransactionState) => state.supplierTransactionsCurrentPage,
  supplierTransactionsCount: (state: SupplierTransactionState) => state.supplierTransactionsCount
}

const { read } = getStoreAccessors<SupplierTransactionState, RootState>('')

export const readSupplierTransactionsCurrentPage = read(getters.supplierTransactionsCurrentPage)
export const readSupplierTransactionsCount = read(getters.supplierTransactionsCount)
