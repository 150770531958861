export const components = {
  chipLabel_material_id: 'Material',
  chipLabel_supplier_id: 'Supplier',
  chipLabel_status: 'State',
  chipLabel_customer_id: 'Customer',
  chipLabel_batch_output: 'Output batch',
  chipLabel_batch_input: 'Input batch',
  chipLabel_message: 'Message',
  chipLabel_machine_id: 'Machine',
  chipLabel_code: 'Code'
}
