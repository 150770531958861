import { AuthState } from '@/store/auth/state'
import { mutations } from '@/store/auth/mutations'
import { getters } from '@/store/auth/getters'
import { actions } from '@/store/auth/actions'

const defaultState: AuthState = {
  hasProjectManagementPermissionCreate: false,
  hasProjectManagementPermissionEdit: false,
  hasProjectManagementPermissionRead: false,
  hasBoardManagementRead: false,
  hasBoardManagementEdit: false,
  hasCustomersManagementEdit: false,
  hasCustomersManagementRead: false,
  hasSuppliersManagementEdit: false,
  hasSuppliersManagementRead: false,
  hasProductsManagementEdit: false,
  hasProductsManagementRead: false,
  hasCalendarManagementEdit: false,
  hasCalendarManagementRead: false,
  hasForumManagementRead: false,
  hasForumManagementEdit: false,
  hasDocumentsManagementEdit: false,
  hasDocumentsManagementRead: false,
  hasMeetingsManagementEdit: false,
  hasMeetingsManagementRead: false,
  hasKPIManagementEdit: false,
  hasKPIManagementRead: false,
  hasOrganigramManagementEdit: false,
  hasOrganigramManagementRead: false,
  hasPermissionsManagementRead: false,
  hasProjectTemplatesManagementEdit: false,
  hasProjectTemplatesManagementRead: false,
  hasHeadquartersManagementRead: false,
  hasHeadquartersManagementEdit: false,
  hasResourcesManagementEdit: false,
  hasResourcesManagementRead: false,
  hasKeyContactsManagementEdit: false,
  hasKeyContactsManagementRead: false,
  hasRolesManagementEdit: false,
  hasRolesManagementRead: false,
  hasUsersManagementEdit: false,
  hasUsersManagementRead: false,
  hasDemoManagement: false,
  hasImportManagementEdit: false,
  hasImportManagementRead: false
}

export const authModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  getters,
  actions
}
