import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import i18n from './i18n'

// COLORS
// https://vuetifyjs.com/en/styles/colors/#material-colors

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#fbb907',
        secondary: '#064e90',
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.indigo.lighten5
      },
      dark: {
        primary: '#fbb907',
        secondary: '#064e90',
        background: '#064e90'
      }
    }
  }
})
