import { RouteNames } from '@/router/names'

export const SupplierTransactionsRoute = {
  path: 'supplier_transactions',
  component: () => import(/* webpackChunkName: "supplier_transactions" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_SUPPLIER_TRANSACTIONS,
      component: () => import(/* webpackChunkName: "supplier_transactions" */ '@/modules/supplier_transaction/views/FFSupplierTransactions.vue')
    },
    {
      path: 'rows/:supplier_transaction_id',
      name: RouteNames.FF_SUPPLIER_TRANSACTION_ROWS,
      component: () => import(/* webpackChunkName: "supplier_transaction_rows" */ '@/modules/supplier_transaction/views/FFSupplierTransactionRows.vue')
    },
    {
      path: 'new',
      name: RouteNames.FF_NEW_SUPPLIER_TRANSACTIONS,
      component: () => import(/* webpackChunkName: "new_supplier_transaction" */ '@/modules/supplier_transaction/views/FFNewSupplierTransaction.vue')
    }
  ]
}
