export const navigation = {
  adminTools: 'Amministrazione',
  roles: 'Ruoli',
  users: 'Utenti',
  home: 'Dashboard',
  warehouse: 'Magazzini',
  banking: 'Conto corrente',
  bom: 'Distinte base',
  supplierTransactions: 'Transazioni fornitori',
  price: 'Listini',
  create_odp: 'Nuovo ordine di produzione',
  scheduler: 'Pianificazione',
  machines: 'Macchine',
  customerRequests: 'Richieste clienti',
  offers: 'Offerte',
  customerTransactions: 'Transazioni clienti'
}
