import repository from '@/repositories/appAxios'

const resourceCustomerRequests = '/customer_requests'

const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll () {
    return repository.get(`${resourceCustomerRequests}`, config)
    // const pagingQuery = composePaginationQuery(getInput.paging)
    // let filterQuery = ''
    // if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    // return repository.get(`${resourceCustomerRequests}?${pagingQuery}${filterQuery}`, config)
  }
}
