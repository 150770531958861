import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { BankingState } from './state'

const defaultState: BankingState = {
  bankingCount: 0,
  bankingCurrentPage: []
}

export const bankingModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
