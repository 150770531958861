
import { Vue, Component, Watch } from 'vue-property-decorator'
import { AppNotification } from 'kwik-vue/store/main/state'
import { commitKwikRemoveNotification } from 'kwik-vue/store/main/mutations'
import { readKwikFirstNotification } from 'kwik-vue/store/main/getters'
import { dispatchKwikRemoveNotification } from 'kwik-vue/store/main/actions'

@Component
export default class NotificationsManager extends Vue {
    public show = false;
    public text = '';
    public showProgress = false;
    public currentNotification: AppNotification | false = false;

    public async hide () {
      this.show = false
      await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500))
    }

    public async close () {
      await this.hide()
      await this.removeCurrentNotification()
    }

    public async removeCurrentNotification () {
      if (this.currentNotification) {
        commitKwikRemoveNotification(this.$store, this.currentNotification)
      }
    }

    public get firstNotification () {
      return readKwikFirstNotification(this.$store)
    }

    public async setNotification (notification: AppNotification | false) {
      if (this.show) {
        await this.hide()
      }
      if (notification) {
        this.currentNotification = notification
        this.showProgress = notification.showProgress || false
        this.show = true
      } else {
        this.currentNotification = false
      }
    }

    @Watch('firstNotification')
    public async onNotificationChange (
      newNotification: AppNotification | false
    ) {
      if (newNotification !== this.currentNotification) {
        await this.setNotification(newNotification)
        if (newNotification) {
          dispatchKwikRemoveNotification(this.$store, { notification: newNotification, timeout: 4000 })
        }
      }
    }

    public get currentNotificationContent () {
      return (this.currentNotification && this.currentNotification.content) || ''
    }

    public get currentNotificationColor () {
      return (this.currentNotification && this.currentNotification.color) || 'info'
    }
}
