import { ActionContext } from 'vuex'
import { getStoreAccessors } from 'typesafe-vuex'
import { StoreNamespaceNames } from '@/store/names'
import { RootState } from '@/store'
import { AuthState } from '@/store/auth/state'
import { commitSetAppRoles } from '@/store/auth/mutations'

type MainContext = ActionContext<AuthState, RootState>;

export const actions = {
  async actionSetAppRoles (context: MainContext, payload: any) {
    commitSetAppRoles(context, payload)
  }
}

const { dispatch } = getStoreAccessors<AuthState | any, RootState>('')
export const dispatchSetAppRoles = dispatch(actions.actionSetAppRoles)
