import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { WarehouseState } from './state'

export const getters = {
  warehouseCurrentPage: (state: WarehouseState) => state.warehouseCurrentPage,
  warehouseCount: (state: WarehouseState) => state.warehouseCount,
  warehouseMaterials: (state: WarehouseState) => state.warehouseMaterials,
  batchesCurrentPage: (state: WarehouseState) => state.batchesCurrentPage,
  batchesCount: (state: WarehouseState) => state.batchesCount
}

const { read } = getStoreAccessors<WarehouseState, RootState>('')

export const readWarehouseCurrentPage = read(getters.warehouseCurrentPage)
export const readWarehouseCount = read(getters.warehouseCount)
export const readWarehouseMaterials = read(getters.warehouseMaterials)
export const readBatchesCurrentPage = read(getters.batchesCurrentPage)
export const readBatchesCount = read(getters.batchesCount)
