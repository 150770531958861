import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import customerTransactionsRepository from '../repository'
import { CustomerTransactionState } from './state'
import { commitSetCustomerTransactionsCurrentPage } from './mutations'
import { IBatchesForShippingGetInput, ICustomerTransactionGetInput, IShipInput } from '../types'

type MainContext = ActionContext<CustomerTransactionState, RootState>;

export const actions = {
  async actionGetCustomerTransactionsCurrentPage (context: MainContext, payload: ICustomerTransactionGetInput) {
    try {
      const response = await customerTransactionsRepository.getAll(payload)
      if (response) {
        commitSetCustomerTransactionsCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetCustomerTransactionRows (context: MainContext, customerTransactionId: number) {
    try {
      const response = await customerTransactionsRepository.getRowsByCustomerTransactionId(customerTransactionId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetBatchesForShipping (context: MainContext, payload: IBatchesForShippingGetInput) {
    try {
      const response = await customerTransactionsRepository.getBatchesForShipping(payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionShip (context: MainContext, payload: IShipInput) {
    try {
      const response = await customerTransactionsRepository.ship(payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionRecallTransaction (context: MainContext, customerTransactionId: number) {
    try {
      const response = await customerTransactionsRepository.recallTransaction(customerTransactionId)
      if (response) {
        return response
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionRecallAndReturnTransaction (context: MainContext, customerTransactionId: number) {
    try {
      const response = await customerTransactionsRepository.recallAndReturnTransaction(customerTransactionId)
      if (response) {
        return response
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<CustomerTransactionState | any, RootState>('')

export const dispatchGetCustomerTransactionsCurrentPage = dispatch(actions.actionGetCustomerTransactionsCurrentPage)
export const dispatchGetCustomerTransactionRows = dispatch(actions.actionGetCustomerTransactionRows)
export const dispatchGetBatchesForShipping = dispatch(actions.actionGetBatchesForShipping)
export const dispatchShip = dispatch(actions.actionShip)
export const dispatchRecallTransaction = dispatch(actions.actionRecallTransaction)
export const dispatchRecallAndReturnTransaction = dispatch(actions.actionRecallAndReturnTransaction)
