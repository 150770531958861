import { RouteNames } from '@/router/names'

export const OfferingsRoute = {
  path: 'offerings',
  component: () => import(/* webpackChunkName: "offerings" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_OFFERINGS,
      component: () => import(/* webpackChunkName: "offerings" */ '@/modules/offering/views/FFOfferings.vue')
    }
  ]
}
