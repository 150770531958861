import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import warehouseRepository from '../repository/company_warehouse'
import companyWarehouseModel from '../repository/company_warehouse_model'
import batchesRepository from '../repository/company_batch'
import { WarehouseState } from './state'
import { commitSetBatchesCurrentPage, commitSetWarehouseCurrentPage, commitSetWarehouseMaterials } from './mutations'
import { IBatchesGetInput, IWarehouseGetInput } from '../types'
import { commitKwikAddNotification } from 'kwik-vue/store/main/mutations'

type MainContext = ActionContext<WarehouseState, RootState>;

export const actions = {
  async actionGetWarehouseCurrentPage (context: MainContext, payload: IWarehouseGetInput) {
    try {
      const response = await warehouseRepository.getAll(payload)
      if (response) {
        commitSetWarehouseCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetCompanyWarehouseModels (context: MainContext) {
    try {
      const response = await companyWarehouseModel.getAll()
      if (response) {
        return response.data.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetCompanyBatches (context: MainContext, payload: IBatchesGetInput) {
    try {
      const response = await batchesRepository.getAll(payload)
      if (response) {
        commitSetBatchesCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetWarehouseMaterials (context: MainContext) {
    try {
      const response = await warehouseRepository.getMaterials()
      if (response) {
        commitSetWarehouseMaterials(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionDisposeBatch (context: MainContext, batchId: number) {
    try {
      const response = await batchesRepository.disposeBatch(batchId)
      if (response) {
        return response
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionReturnBatch (context: MainContext, batchId: number) {
    try {
      const response = await batchesRepository.returnBatch(batchId)
      if (response) {
        return response
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionCanImproveWarehouse (context: MainContext, companyWarehouseId: number) {
    try {
      const response = await warehouseRepository.canImprove(companyWarehouseId)
      if (response) {
        return response
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionImproveWarehouse (context: MainContext, machineId: number) {
    try {
      const response = await warehouseRepository.improveWarehouse(machineId)
      if (response) {
        commitKwikAddNotification(context, {
          content: 'Magazzino migliorato',
          color: 'success'
        })
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<WarehouseState | any, RootState>('')

export const dispatchGetWarehouseCurrentPage = dispatch(actions.actionGetWarehouseCurrentPage)
export const dispatchGetCompanyWarehouseModels = dispatch(actions.actionGetCompanyWarehouseModels)
export const dispatchGetCompanyBatches = dispatch(actions.actionGetCompanyBatches)
export const dispatchGetWarehouseMaterials = dispatch(actions.actionGetWarehouseMaterials)
export const dispatchDisposeBatch = dispatch(actions.actionDisposeBatch)
export const dispatchReturnBatch = dispatch(actions.actionReturnBatch)
export const dispatchCanImproveWarehouse = dispatch(actions.actionCanImproveWarehouse)
export const dispatchImproveWarehouse = dispatch(actions.actionImproveWarehouse)
