export const navigation = {
  adminTools: 'Admin Tools',
  roles: 'Roles',
  users: 'Users',
  home: 'Dashboard',
  warehouse: 'Warehouse',
  banking: 'Online banking',
  bom: 'Bill of materials',
  supplierTransactions: 'Suppliers transactions',
  price: 'Price lists',
  create_odp: 'Create production order',
  scheduler: 'Scheduler',
  machines: 'Machines',
  customerRequests: 'Customers requests',
  offers: 'Offers',
  customerTransactions: 'Customers transactions'
}
