import _Vue from 'vue'
import { RouteNames } from '@/router/names'
import { KwikRouteNames } from 'kwik-vue/router/names'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'

const CommonNames = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install: function (Vue: typeof _Vue, options?: any) {
    Vue.mixin({
      data () {
        return {
          RouteNames: RouteNames,
          KwikRouteNames: KwikRouteNames,
          FilterTypes: FilterTypes
        }
      }
    })
  }
}
export default CommonNames
