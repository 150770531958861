export const commons = {
  buttons: {
    create: 'Crea',
    add: 'Aggiungi',
    insert: 'Inserisci',
    save: 'Salva',
    cancel: 'Annulla',
    reset: 'Reset',
    edit: 'Modifica',
    delete: 'Elimina',
    filter: 'Filtri',
    filterTooltip: 'Permette di impostare i filtri sulla tabella',
    actions: 'Comandi',
    confirm: 'Conferma',
    download: 'Scarica',
    downloadCSV: 'Scarica .csv',
    downloadXLSX: 'Scarica .xlsx'
  },
  dialogs: {
    confirmationRequired: 'Confermare la richiesta'
  },
  hours: 'ore'
}
