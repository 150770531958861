import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { AuthState } from '@/store/auth/state'
import { IUserWithPermissionsAndRoles } from 'kwik-vue'

export const mutations = {
  setAppRoles (state: AuthState, me: IUserWithPermissionsAndRoles) {
    // state.hasDemoManagement = me.permissions.find((permission) => permission.name === PermissionNames.DEMO_MANAGEMENT) !== undefined
  }
}

const { commit } = getStoreAccessors<AuthState, RootState>('')
export const commitSetAppRoles = commit(mutations.setAppRoles)
