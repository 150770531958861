import { KwikRouteNames } from 'kwik-vue/router/names'

const login = {
  path: 'login',
  name: KwikRouteNames.LOGIN,
  component: () => import(/* webpackChunkName: "login" */ '@/modules/login/views/FFLogin.vue')
}
// const login = {
//   path: 'login',
//   name: KwikRouteNames.LOGIN,
//   component: () => import(/* webpackChunkName: "login" */ 'kwik-vue/views/publics/KwikLogin.vue')
// }

const recover = {
  path: 'recover-password',
  name: KwikRouteNames.RECOVER_PASSWORD,
  component: () => import(/* webpackChunkName: "recover-password" */ 'kwik-vue/views/publics/KwikPasswordRecovery.vue')
}

const reset = {
  path: 'reset-password',
  name: KwikRouteNames.RESET_PASSWORD,
  component: () => import(/* webpackChunkName: "reset-password" */ 'kwik-vue/views/publics/KwikResetPassword.vue')
}

const about = {
  path: 'about',
  name: KwikRouteNames.ABOUT,
  component: () => import(/* webpackChunkName: "about" */ '@/views/about/About.vue')
}

export const PublicRoute = [login, recover, reset, about]
