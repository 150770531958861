import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import statsRepository from '../repository'
import { StatsState } from './state'
import { commitSetLastStats } from './mutations'

type MainContext = ActionContext<StatsState, RootState>;

export const actions = {
  async actionGetStats (context: MainContext) {
    try {
      const response = await statsRepository.get()
      if (response) {
        commitSetLastStats(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<StatsState | any, RootState>('')

export const dispatchGetStats = dispatch(actions.actionGetStats)
