import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { TrackingNoteState } from './state'

const defaultState: TrackingNoteState = {
  trackingNotesCount: 0,
  trackingNotesCurrentPage: []
}

export const trackingNotesModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
