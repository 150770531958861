import { RouteNames } from '@/router/names'

export const PriceListsRoute = {
  path: 'price_lists',
  component: () => import(/* webpackChunkName: "price_lists" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_PRICE_LISTS,
      component: () => import(/* webpackChunkName: "price_lists" */ '@/modules/price_list/views/FFPriceLists.vue')
    }
  ]
}
