import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { CustomerTransactionState } from './state'

const defaultState: CustomerTransactionState = {
  customerTransactionsCount: 0,
  customerTransactionsCurrentPage: []
}

export const customerTransactionsModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
