import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import notificationsRepository from '../repository'
import { NotificationState } from './state'
import { commitSetNotificationsCurrentPage } from './mutations'
import { INotificationGetInput } from '../types'

type MainContext = ActionContext<NotificationState, RootState>;

export const actions = {
  async actionGetNotificationsCurrentPage (context: MainContext, payload: INotificationGetInput) {
    try {
      const response = await notificationsRepository.getAll(payload)
      if (response) {
        commitSetNotificationsCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<NotificationState | any, RootState>('')

export const dispatchGetNotificationsCurrentPage = dispatch(actions.actionGetNotificationsCurrentPage)
