import { ThemeSelector } from '@/interfaces/theme'

export const getCurrentLocale = () => localStorage.getItem('current_locale')

export const saveCurrentLocale = (locale: string) => localStorage.setItem('current_locale', locale)

export const removeCurrentLocale = () => localStorage.removeItem('current_locale')

export const getSelectedTheme = () => localStorage.getItem('selected_theme')

export const saveSelectedTheme = (theme: ThemeSelector) => localStorage.setItem('selected_theme', theme)

export const removeSelectedTheme = () => localStorage.removeItem('selected_theme')
