import repository from '@/repositories/appAxios'

const resource = 'company/stats'

const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  get () {
    return repository.get(`${resource}`, config)
  }
}
