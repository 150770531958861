import { RouteNames } from '@/router/names'

export const CompanyMachineRoute = {
  path: 'company_machines',
  component: () => import(/* webpackChunkName: "company_machines" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_MACHINES,
      component: () => import(/* webpackChunkName: "company_machines" */ '@/modules/company_machines/views/FFCompanyMachine.vue')
    }
  ]
}
