import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { CustomerTransactionState } from './state'

export const getters = {
  customerTransactionsCurrentPage: (state: CustomerTransactionState) => state.customerTransactionsCurrentPage,
  customerTransactionsCount: (state: CustomerTransactionState) => state.customerTransactionsCount
}

const { read } = getStoreAccessors<CustomerTransactionState, RootState>('')

export const readCustomerTransactionsCurrentPage = read(getters.customerTransactionsCurrentPage)
export const readCustomerTransactionsCount = read(getters.customerTransactionsCount)
