import { RouteNames } from '@/router/names'

export const CustomerTransactionsRoute = {
  path: 'customer_transactions',
  component: () => import(/* webpackChunkName: "customer_transactions" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_CUSTOMER_TRANSACTIONS,
      component: () => import(/* webpackChunkName: "customer_transactions" */ '@/modules/customer_transaction/views/FFCustomerTransactions.vue')
    },
    {
      path: 'rows/:customer_transaction_id',
      name: RouteNames.FF_CUSTOMER_TRANSACTION_ROWS,
      component: () => import(/* webpackChunkName: "customer_transaction_rows" */ '@/modules/customer_transaction/views/FFCustomerTransactionRows.vue')
    },
    {
      path: 'new',
      name: RouteNames.FF_NEW_CUSTOMER_TRANSACTION,
      component: () => import(/* webpackChunkName: "new_customer_transaction" */ '@/modules/customer_transaction/views/FFNewCustomerTransaction.vue')
    }
  ]
}
