import { RouteNames } from '@/router/names'

export const WarehouseRoute = {
  path: 'warehouse',
  component: () => import(/* webpackChunkName: "warehouse" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_WAREHOUSE,
      component: () => import(/* webpackChunkName: "warehouse" */ '@/modules/warehouse/views/FFWarehouse.vue')
    }
  ]
}
