import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { NotificationState } from './state'
import { INotification } from '../types'

export const mutations = {
  setNotificationsCurrentPage (state: NotificationState, payload: IPaginatedResult<INotification>) {
    state.notificationsCurrentPage = payload.data
    state.notificationsCount = payload.total
  }
}

const { commit } = getStoreAccessors<NotificationState | any, RootState>('')
export const commitSetNotificationsCurrentPage = commit(mutations.setNotificationsCurrentPage)
