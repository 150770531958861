import { RouteNames } from '@/router/names'

export const companyBatchesRoute = {
  path: 'warehouse-batches',
  component: () => import(/* webpackChunkName: "company-batches" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_BATCHES,
      component: () => import(/* webpackChunkName: "company-batches" */ '@/modules/warehouse/views/FFCompanyBatches.vue')
    }
  ]
}
