import { RouteNames } from '@/router/names'

export const BillOfMaterialsRoute = {
  path: 'bill_of_materials',
  component: () => import(/* webpackChunkName: "bill_of_materials" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_BOM,
      component: () => import(/* webpackChunkName: "bill_of_materials" */ '@/modules/bill_of_materials/views/FFBillOfMaterials.vue')
    },
    {
      path: 'rows/:product_id',
      name: RouteNames.FF_BOM_DETAIL,
      component: () => import(/* webpackChunkName: "bill_of_material_detail" */ '@/modules/bill_of_materials/views/FFBillOfMaterialDetail.vue')
    }
  ]
}
