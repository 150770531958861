import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { StatsState } from './state'

export const getters = {
  lastStats: (state: StatsState) => state.lastStats
}

const { read } = getStoreAccessors<StatsState, RootState>('')

export const readLastStats = read(getters.lastStats)
