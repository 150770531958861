import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { WarehouseState } from './state'

const defaultState: WarehouseState = {
  warehouseCount: 0,
  warehouseCurrentPage: [],
  warehouseMaterials: [],
  batchesCount: 0,
  batchesCurrentPage: []
}

export const warehouseModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
