import { actions } from './actions'
import { BillOfMaterialsState } from './state'

const defaultState: BillOfMaterialsState = {
  bomStub: 0
}

export const billOfMaterialsModule = {
  namespaced: false,
  state: defaultState,
  actions
}
