import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import companyMachinesRepository from '../repository'
import { CompanyMachineState } from './state'
import { commitSetCompanyMachines } from './mutations'
import { commitKwikAddNotification } from 'kwik-vue/store/main/mutations'

type MainContext = ActionContext<CompanyMachineState, RootState>;

export const actions = {
  async actionGetCompanyMachines (context: MainContext) {
    try {
      const response = await companyMachinesRepository.getAll()
      if (response) {
        commitSetCompanyMachines(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetDirectlyCompanyMachines (context: MainContext) {
    try {
      const response = await companyMachinesRepository.getAll()
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionBuyMachine (context: MainContext, machineId: number) {
    try {
      const response = await companyMachinesRepository.buyMachine(machineId)
      if (response) {
        commitKwikAddNotification(context, {
          content: 'Macchina acquistata',
          color: 'success'
        })
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetShopCleaning (context: MainContext, companyShopId: number) {
    try {
      const response = await companyMachinesRepository.getShopCleaning(companyShopId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetShopSetup (context: MainContext, companyShopId: number) {
    try {
      const response = await companyMachinesRepository.getShopSetup(companyShopId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<CompanyMachineState | any, RootState>('')

export const dispatchGetCompanyMachines = dispatch(actions.actionGetCompanyMachines)
export const dispatchGetDirectlyCompanyMachines = dispatch(actions.actionGetDirectlyCompanyMachines)
export const dispatchBuyMachine = dispatch(actions.actionBuyMachine)
export const dispatchGetShopCleaning = dispatch(actions.actionGetShopCleaning)
export const dispatchGetShopSetup = dispatch(actions.actionGetShopSetup)
