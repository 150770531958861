import repository from '@/repositories/appAxios'

const resource = '/company/bom/product'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getByProductsId (productId: number) {
    return repository.get(`${resource}/${productId}`, config)
  },
  getByCompanyShopId (companyShopId: number) {
    return repository.get(`/company/bom/company_shop/${companyShopId}`, config)
  },
  getByMasterParentId (masterParentId: number) {
    return repository.get(`/company/bom/master_parent/${masterParentId}`, config)
  }
}
