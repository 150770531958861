import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { WarehouseState } from './state'
import { IBatch, IWarehouse, IWarehouseMaterial } from '../types'

export const mutations = {
  setWarehouseCurrentPage (state: WarehouseState, payload: IPaginatedResult<IWarehouse>) {
    state.warehouseCurrentPage = payload.data
    state.warehouseCount = payload.total
  },
  setBatchesCurrentPage (state: WarehouseState, payload: IPaginatedResult<IBatch>) {
    state.batchesCurrentPage = payload.data
    state.batchesCount = payload.total
  },
  setWarehouseMaterials (state: WarehouseState, payload: IWarehouseMaterial[]) {
    state.warehouseMaterials = payload
  }
}

const { commit } = getStoreAccessors<WarehouseState | any, RootState>('')
export const commitSetWarehouseCurrentPage = commit(mutations.setWarehouseCurrentPage)
export const commitSetBatchesCurrentPage = commit(mutations.setBatchesCurrentPage)
export const commitSetWarehouseMaterials = commit(mutations.setWarehouseMaterials)
