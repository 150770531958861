import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import supplierTransactionsRepository from '../repository'
import { SupplierTransactionState } from './state'
import { commitSetSupplierTranactionsCurrentPage } from './mutations'
import { BuyPayload, ISupplierTransactionGetInput } from '../types'

type MainContext = ActionContext<SupplierTransactionState, RootState>;

export const actions = {
  async actionGetSupplierTransactionsCurrentPage (context: MainContext, payload: ISupplierTransactionGetInput) {
    try {
      const response = await supplierTransactionsRepository.getAll(payload)
      if (response) {
        commitSetSupplierTranactionsCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetSupplierTransactionRows (context: MainContext, supplierTransactionId: number) {
    try {
      const response = await supplierTransactionsRepository.getRowsBySupplierTransactionId(supplierTransactionId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionBuyFromSupplier (context: MainContext, payload: BuyPayload) {
    try {
      const response = await supplierTransactionsRepository.buy(payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionAcceptSupplierTransaction (context: MainContext, supplierTransactionId: number) {
    try {
      const response = await supplierTransactionsRepository.accept(supplierTransactionId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<SupplierTransactionState | any, RootState>('')

export const dispatchGetSupplierTransactionsCurrentPage = dispatch(actions.actionGetSupplierTransactionsCurrentPage)
export const dispatchGetSupplierTransactionRows = dispatch(actions.actionGetSupplierTransactionRows)
export const dispatchBuyFromSupplier = dispatch(actions.actionBuyFromSupplier)
export const dispatchAcceptSupplierTransaction = dispatch(actions.actionAcceptSupplierTransaction)
