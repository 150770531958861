import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { NotificationState } from './state'

export const getters = {
  notificationsCurrentPage: (state: NotificationState) => state.notificationsCurrentPage,
  notificationsCount: (state: NotificationState) => state.notificationsCount
}

const { read } = getStoreAccessors<NotificationState, RootState>('')

export const readNotificationsCurrentPage = read(getters.notificationsCurrentPage)
export const readNotificationsCount = read(getters.notificationsCount)
