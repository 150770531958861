import repository from '@/repositories/appAxios'
import { composePaginationQuery } from 'kwik-vue/utils/urlQuery'
import { IBatchesForShippingGetInput, ICustomerTransactionGetInput, IShipInput } from '../types'

const resourceCustomerTransactions = '/company/customer_transactions'
const resourceCustomerTransactionRows = '/company/customer_transaction'

const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: ICustomerTransactionGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    if (getInput.customer_id) filterQuery += `&customer_id=${encodeURIComponent(getInput.customer_id)}`
    if (getInput.status) filterQuery += `&status=${encodeURIComponent(getInput.status)}`
    return repository.get(`${resourceCustomerTransactions}?${pagingQuery}${filterQuery}`, config)
  },
  getRowsByCustomerTransactionId (customerTransactionId: number) {
    return repository.get(`${resourceCustomerTransactionRows}/${customerTransactionId}`, config)
  },
  getBatchesForShipping (payload: IBatchesForShippingGetInput) {
    return repository.get(`/company/batches_for_shipping?product_id=${payload.product_id}&expiration_clock=${payload.expiration_clock}`, config)
  },
  ship (payload: IShipInput) {
    return repository.post('/company/ship', payload, config)
  },
  recallTransaction (customerTransactionId: number) {
    return repository.put(`/company/recall?customer_transaction_id=${customerTransactionId}`, {}, config)
  },
  recallAndReturnTransaction (customerTransactionId: number) {
    return repository.put(`/company/recall_and_return?customer_transaction_id=${customerTransactionId}`, {}, config)
  }
}
