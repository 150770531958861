import { en } from 'vuetify/src/locale'
import kwikEn from 'kwik-vue/locale/en'
import { menu } from '@/locales/en-US/menu'
import { commons } from '@/locales/en-US/commons'
import { navigation } from '@/locales/en-US/navigation'
import { components } from '@/locales/en-US/components'
import { offering } from '@/modules/offering/locale/en-US'
import { customerTransaction } from '@/modules/customer_transaction/locale/en-US'
import { supplierTransaction } from '@/modules/supplier_transaction/locale/en-US'
import { production } from '@/modules/production_orders/locale/en-US'

const messages = {
  menu,
  commons,
  navigation,
  components,
  offering,
  customerTransaction,
  supplierTransaction,
  production,
  $vuetify: { ...en },
  $kwik: { ...kwikEn }
}

export default {
  locale: 'en',
  messages: messages
}
