import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { CustomerRequestsState } from './state'

const defaultState: CustomerRequestsState = {
  customerRequests: null
}

export const customerRequestsModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
