import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { ProductionOrdersState } from './state'

export const getters = {
  productionOrdersCurrentPage: (state: ProductionOrdersState) => state.productionOrdersCurrentPage,
  productionOrdersCount: (state: ProductionOrdersState) => state.productionOrdersCount
}

const { read } = getStoreAccessors<ProductionOrdersState, RootState>('')

export const readProductionOrdersCurrentPage = read(getters.productionOrdersCurrentPage)
export const readProductionOrdersCount = read(getters.productionOrdersCount)
