import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import customerTransactionsRepository from '../repository'
import { CustomerRequestsState } from './state'
import { commitSetCustomerRequests } from './mutations'

type MainContext = ActionContext<CustomerRequestsState, RootState>;

export const actions = {
  async actionGetCustomerRequests (context: MainContext) {
    try {
      const response = await customerTransactionsRepository.getAll()
      if (response) {
        commitSetCustomerRequests(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<CustomerRequestsState | any, RootState>('')

export const dispatchGetCustomerRequests = dispatch(actions.actionGetCustomerRequests)
