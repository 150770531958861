import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { OfferingState } from './state'

export const getters = {
  offeringsCurrentPage: (state: OfferingState) => state.offeringsCurrentPage,
  offeringsCount: (state: OfferingState) => state.offeringsCount
}

const { read } = getStoreAccessors<OfferingState, RootState>('')

export const readOfferingsCurrentPage = read(getters.offeringsCurrentPage)
export const readOfferingsCount = read(getters.offeringsCount)
