import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { PriceListState } from './state'
import { IPriceList } from '../types'

export const mutations = {
  setPriceListsCurrentPage (state: PriceListState, payload: IPaginatedResult<IPriceList>) {
    state.priceListsCurrentPage = payload.data
    state.priceListsCount = payload.total
  }
}

const { commit } = getStoreAccessors<PriceListState | any, RootState>('')
export const commitSetPriceListsCurrentPage = commit(mutations.setPriceListsCurrentPage)
