import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import matchRepository from '../repository'
import { commitSetCurrentMatch } from './mutations'
import { MatchState } from './state'

type MainContext = ActionContext<MatchState, RootState>;

export const actions = {
  async actionGetCurrentMatch (context: MainContext) {
    try {
      const response = await matchRepository.get()
      if (response) {
        commitSetCurrentMatch(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<MatchState | any, RootState>('')

export const dispatchGetCurrentMatch = dispatch(actions.actionGetCurrentMatch)
