// Create VueI18n instance with options
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import english from '@/locales/en-US/index'
import italian from '@/locales/it-IT/index'

Vue.use(VueI18n)

const i18n = new VueI18n({
  dateTimeFormats: {
    en: {
      shortDateTime: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      },
      date: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      time: {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      },
      shortTime: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      },
      datetime: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }
    },
    it: {
      shortDateTime: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      date: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      time: {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      },
      shortTime: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      datetime: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
    }
  },
  locale: 'it', // set locale
  fallbackLocale: 'it',
  silentTranslationWarn: true,
  messages: {
    en: english.messages,
    it: italian.messages
  }
})

export default i18n
