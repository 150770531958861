import './component-hooks'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'vuetify/dist/vuetify.min.css'

import CommonNames from '@/plugins/commonNames'
import Validators from 'kwik-vue/plugins/validators'
import '@/assets/xto.css'
import './components/kit/vendors/dhtmlx/dhtmlxscheduler_material.css'
import i18n from '@/plugins/i18n'

import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'
import { socketUrl } from '@/env'

Vue.config.productionTip = false
Vue.use(CommonNames)
Vue.use(Validators)

const socket = io(socketUrl)
Vue.use(VueSocketIOExt, socket)

declare global {interface Window {
  scheduler: any
}}

Vue.prototype.$scheduler = window.scheduler
Vue.prototype.$formatDate = 'YYYY/MM/DD HH:mm'

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

document.title = 'The Food Factory'
