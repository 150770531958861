import repository from '@/repositories/appAxios'

const resource = '/company/warehouse_models'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll () {
    return repository.get(`${resource}`, config)
  }
}
