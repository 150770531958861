import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { CustomerTransactionState } from './state'
import { ICustomerTransaction } from '../types'

export const mutations = {
  setCustomerTransactionsCurrentPage (state: CustomerTransactionState, payload: IPaginatedResult<ICustomerTransaction>) {
    state.customerTransactionsCurrentPage = payload.data
    state.customerTransactionsCount = payload.total
  }
}

const { commit } = getStoreAccessors<CustomerTransactionState | any, RootState>('')
export const commitSetCustomerTransactionsCurrentPage = commit(mutations.setCustomerTransactionsCurrentPage)
