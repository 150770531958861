import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { PriceListState } from './state'

export const getters = {
  priceListsCurrentPage: (state: PriceListState) => state.priceListsCurrentPage,
  priceListsCount: (state: PriceListState) => state.priceListsCount
}

const { read } = getStoreAccessors<PriceListState, RootState>('')

export const readPriceListsCurrentPage = read(getters.priceListsCurrentPage)
export const readPriceListsCount = read(getters.priceListsCount)
