import { RouteNames } from '@/router/names'

export const CompanyWarehouseModelsRoute = {
  path: 'warehouse-models',
  component: () => import(/* webpackChunkName: "company-warehouse-models" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_WAREHOUSE_MODELS,
      component: () => import(/* webpackChunkName: "company-warehouse-models" */ '@/modules/warehouse/views/FFWarehouseModels.vue')
    }
  ]
}
