import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { MatchState } from './state'
import { IMatch } from '@/shared/types/match'

export const mutations = {
  setCurrentMatch (state: MatchState, match: IMatch) {
    state.currentMatch = match
    state.currentUniverse = match.activeUniverses[0].universe
    state.currentUserUniverse = match.activeUniverses[0]
    state.currentCompanyShops = match.activeUniverses[0].company.company_shops
    debugger
    state.isInbound = state.currentUserUniverse.departments.findIndex(t => t.department.name === 'Inbound') > -1
    state.isProduction = state.currentUserUniverse.departments.findIndex(t => t.department.name === 'Production') > -1
    state.isOutbound = state.currentUserUniverse.departments.findIndex(t => t.department.name === 'Outbound') > -1
  },
  setClock (state: MatchState, clock: number) {
    state.clock = clock
  }
}

const { commit } = getStoreAccessors<MatchState | any, RootState>('')
export const commitSetCurrentMatch = commit(mutations.setCurrentMatch)
export const commitSetClock = commit(mutations.setClock)
