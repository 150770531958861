import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import offeringsRepository from '../repository'
import { OfferingState } from './state'
import { commitSetOfferingsCurrentPage } from './mutations'
import { IOfferingGetInput, IOfferPayload } from '../types'

type MainContext = ActionContext<OfferingState, RootState>;

export const actions = {
  async actionGetOfferingsCurrentPage (context: MainContext, payload: IOfferingGetInput) {
    try {
      const response = await offeringsRepository.getAll(payload)
      if (response) {
        commitSetOfferingsCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionPlaceOffer (context: MainContext, payload: IOfferPayload) {
    try {
      const response = await offeringsRepository.offer(payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<OfferingState | any, RootState>('')

export const dispatchGetOfferingsCurrentPage = dispatch(actions.actionGetOfferingsCurrentPage)
export const dispatchPlaceOffer = dispatch(actions.actionPlaceOffer)
