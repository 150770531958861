import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { BankingState } from './state'

export const getters = {
  bankingCurrentPage: (state: BankingState) => state.bankingCurrentPage,
  bankingCount: (state: BankingState) => state.bankingCount
}

const { read } = getStoreAccessors<BankingState, RootState>('')

export const readBankingCurrentPage = read(getters.bankingCurrentPage)
export const readBankingCount = read(getters.bankingCount)
