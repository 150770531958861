import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { MatchState } from './state'

export const getters = {
  currentMatch: (state: MatchState) => state.currentMatch,
  currentUniverse: (state: MatchState) => state.currentUniverse,
  currentUserUniverse: (state: MatchState) => state.currentUserUniverse,
  currentCompanyShops: (state: MatchState) => state.currentCompanyShops,
  clock: (state: MatchState) => state.clock,
  isInbound: (state: MatchState) => state.isInbound,
  isOutbound: (state: MatchState) => state.isOutbound,
  isProduction: (state: MatchState) => state.isProduction,
  isManager: (state: MatchState) => state.isManager
}

const { read } = getStoreAccessors<MatchState, RootState>('')

export const readCurrentMatch = read(getters.currentMatch)
export const readCurrentUniverse = read(getters.currentUniverse)
export const readCurrentUserUniverse = read(getters.currentUserUniverse)
export const readCurrentCompanyShops = read(getters.currentCompanyShops)
export const readClock = read(getters.clock)
export const readIsInbound = read(getters.isInbound)
export const readIsOutbound = read(getters.isOutbound)
export const readIsProduction = read(getters.isProduction)
export const readIsManager = read(getters.isManager)
