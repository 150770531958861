import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { MatchState } from './state'

const defaultState: MatchState = {
  currentMatch: null,
  currentUniverse: null,
  currentUserUniverse: null,
  currentCompanyShops: [],
  clock: 0,
  isInbound: false,
  isOutbound: false,
  isProduction: false,
  isManager: false
}

export const matchModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
