import router from 'kwik-vue/router'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { PublicRoute } from '@/router/public'
import { DashboardRoute } from '@/modules/dashboard/routes/dashboard'
import { WarehouseRoute } from '@/modules/warehouse/routes/companyWarehouse'
import { BankingRoute } from '@/modules/banking/routes'
import { CompanyMachineRoute } from '@/modules/company_machines/routes'
import { SupplierTransactionsRoute } from '@/modules/supplier_transaction/routes'
import { PriceListsRoute } from '@/modules/price_list/routes'
import { OfferingsRoute } from '@/modules/offering/routes'
import { CustomerTransactionsRoute } from '@/modules/customer_transaction/routes'
import { ProductionOrdersRoute } from '@/modules/production_orders/routes'
import { CompanyWarehouseModelsRoute } from '@/modules/warehouse/routes/companyWarehouseModels'
import { CustomerRequestsRoute } from '@/modules/customer_requests/routes'
import { BillOfMaterialsRoute } from '@/modules/bill_of_materials/routes'
import { companyBatchesRoute } from '@/modules/warehouse/routes/companyBatches'

Vue.use(VueRouter)

router.addRoute({
  path: '/',
  component: () => import(/* webpackChunkName: "start" */ '@/layouts/Start.vue'),
  children: [
    ...PublicRoute,
    DashboardRoute,
    WarehouseRoute,
    BankingRoute,
    CompanyMachineRoute,
    SupplierTransactionsRoute,
    PriceListsRoute,
    OfferingsRoute,
    CustomerTransactionsRoute,
    ProductionOrdersRoute,
    CompanyWarehouseModelsRoute,
    CustomerRequestsRoute,
    BillOfMaterialsRoute,
    companyBatchesRoute
  ]
})
export default router
