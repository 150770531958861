import repository from '@/repositories/appAxios'
import { composePaginationQuery } from '@/utils/pagination'
import { IPriceListGetInput } from '../types'

const resource = 'supplier-materials/supplier-materials'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: IPriceListGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    if (getInput.supplierId) filterQuery += `&supplier_id=${encodeURIComponent(getInput.supplierId)}`
    if (getInput.materialId) filterQuery += `&material_id=${encodeURIComponent(getInput.materialId)}`
    return repository.get(`${resource}?${pagingQuery}${filterQuery}`, config)
  }
}
