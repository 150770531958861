import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import bankingRepository from '../repository'
import { BankingState } from './state'
import { IBankingGetInput } from '../types'
import { commitSetBankingCurrentPage } from './mutations'

type MainContext = ActionContext<BankingState, RootState>;

export const actions = {
  async actionGetBankingCurrentPage (context: MainContext, payload: IBankingGetInput) {
    try {
      const response = await bankingRepository.getAll(payload)
      if (response) {
        commitSetBankingCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<BankingState | any, RootState>('')

export const dispatchGetBankingCurrentPage = dispatch(actions.actionGetBankingCurrentPage)
