import repository from '@/repositories/appAxios'
import { composePaginationQuery } from '@/utils/pagination'
import { BuyPayload, ISupplierTransactionGetInput } from '../types'

const resourceSupplierTransactions = '/company/supplier_transactions'
const resourceSupplierTransactionRows = '/company/supplier_transaction_rows'

const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: ISupplierTransactionGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    if (getInput.supplier_id) filterQuery += `&supplier_id=${encodeURIComponent(getInput.supplier_id)}`
    if (getInput.status) filterQuery += `&status=${encodeURIComponent(getInput.status)}`
    return repository.get(`${resourceSupplierTransactions}?${pagingQuery}${filterQuery}`, config)
  },
  getRowsBySupplierTransactionId (supplierTransactionId: number) {
    return repository.get(`${resourceSupplierTransactionRows}/${supplierTransactionId}`, config)
  },
  buy (payload: BuyPayload) {
    return repository.post('company/buy', payload, config)
  },
  accept (supplierTransactionId: number) {
    return repository.put(`${resourceSupplierTransactions}/accept?supplier_transaction_id=${supplierTransactionId}`, {}, config)
  }
}
