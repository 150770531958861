export const components = {
  chipLabel_material_id: 'Materiale',
  chipLabel_supplier_id: 'Fornitore',
  chipLabel_status: 'Stato',
  chipLabel_customer_id: 'Cliente',
  chipLabel_batch_output: 'Lotto output',
  chipLabel_batch_input: 'Lotto input',
  chipLabel_message: 'Circolare',
  chipLabel_machine_id: 'Macchina',
  chipLabel_code: 'Codice'
}
