import { RouteNames } from '@/router/names'

export const ProductionOrdersRoute = {
  path: 'production_orders',
  component: () => import(/* webpackChunkName: "production_orders" */ '@/layouts/Main.vue'),
  children: [
    {
      path: '',
      name: RouteNames.FF_PRODUCTION_ORDERS,
      component: () => import(/* webpackChunkName: "production_orders" */ '@/modules/production_orders/views/FFProductionOrders.vue')
    },
    {
      path: 'new',
      name: RouteNames.FF_CREATE_ODP,
      component: () => import(/* webpackChunkName: "new_production_order" */ '@/modules/production_orders/views/FFNewProductionOrder.vue')
    },
    {
      path: 'rows/:production_orders_id',
      name: RouteNames.FF_PRODUCTION_ORDERS_ROWS,
      component: () => import(/* webpackChunkName: "production_orders_rows" */ '@/modules/production_orders/views/FFProductionOrdersRows.vue')
    }
  ]
}
