import { it } from 'vuetify/src/locale'
import kwikIT from 'kwik-vue/locale/it'
import { menu } from '@/locales/it-IT/menu'
import { commons } from '@/locales/it-IT/commons'
import { navigation } from '@/locales/it-IT/navigation'
import { components } from '@/locales/it-IT/components'
import { offering } from '@/modules/offering/locale/it-IT'
import { customerTransaction } from '@/modules/customer_transaction/locale/it-IT'
import { supplierTransaction } from '@/modules/supplier_transaction/locale/it-IT'
import { production } from '@/modules/production_orders/locale/it-IT'

const messages = {
  menu,
  commons,
  navigation,
  components,
  offering,
  customerTransaction,
  supplierTransaction,
  production,
  $vuetify: { ...it },
  $kwik: { ...kwikIT }
}

export default {
  locale: 'it',
  messages: messages
}
