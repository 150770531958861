import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import billOfMaterialsRepository from '../repository'
import { BillOfMaterialsState } from './state'
import { IBillOfMaterialMaster, ICompanyBillOfMaterial } from '../types'

type MainContext = ActionContext<BillOfMaterialsState, RootState>;

export const actions = {
  async actionGetBillOfMaterials (context: MainContext, productId: number) {
    try {
      const response = await billOfMaterialsRepository.getByProductsId(productId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetBoMByCompanyShopId (context: MainContext, companyShopId: number): Promise<ICompanyBillOfMaterial[] | undefined> {
    try {
      const response = await billOfMaterialsRepository.getByCompanyShopId(companyShopId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetBoMByMasterParentId (context: MainContext, masterParentId: number): Promise<IBillOfMaterialMaster[] | undefined> {
    try {
      const response = await billOfMaterialsRepository.getByMasterParentId(masterParentId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<BillOfMaterialsState | any, RootState>('')

export const dispatchGetBillOfMaterials = dispatch(actions.actionGetBillOfMaterials)
export const dispatchGetBoMByCompanyShopId = dispatch(actions.actionGetBoMByCompanyShopId)
export const dispatchGetBoMByMasterParentId = dispatch(actions.actionGetBoMByMasterParentId)
