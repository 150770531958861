import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { AuthState } from './state'

export const getters = {
  hasProjectManagementPermissionCreate: (state: AuthState) => state.hasProjectManagementPermissionCreate,
  hasProjectManagementPermissionRead: (state: AuthState) => state.hasProjectManagementPermissionRead,
  hasProjectManagementPermissionEdit: (state: AuthState) => state.hasProjectManagementPermissionEdit,

  hasBoardManagementRead: (state: AuthState) => state.hasBoardManagementRead,
  hasBoardManagementEdit: (state: AuthState) => state.hasBoardManagementEdit,

  // system settings
  hasProjectTemplatesManagementRead: (state: AuthState) => state.hasProjectTemplatesManagementRead,
  hasProjectTemplatesManagementEdit: (state: AuthState) => state.hasProjectTemplatesManagementEdit,

  hasHeadquartersManagementRead: (state: AuthState) => state.hasHeadquartersManagementRead,
  hasHeadquartersManagementEdit: (state: AuthState) => state.hasHeadquartersManagementEdit,

  hasCustomersManagementRead: (state: AuthState) => state.hasCustomersManagementRead,
  hasCustomersManagementEdit: (state: AuthState) => state.hasCustomersManagementEdit,

  hasSuppliersManagementRead: (state: AuthState) => state.hasSuppliersManagementRead,
  hasSuppliersManagementEdit: (state: AuthState) => state.hasSuppliersManagementEdit,

  hasProductsManagementRead: (state: AuthState) => state.hasProductsManagementRead,
  hasProductsManagementEdit: (state: AuthState) => state.hasProductsManagementEdit,

  hasCalendarManagementRead: (state: AuthState) => state.hasCalendarManagementRead,
  hasCalendarManagementEdit: (state: AuthState) => state.hasCalendarManagementEdit,

  hasResourcesManagementRead: (state: AuthState) => state.hasResourcesManagementRead,
  hasResourcesManagementEdit: (state: AuthState) => state.hasResourcesManagementEdit,

  hasOrganigramManagementRead: (state: AuthState) => state.hasOrganigramManagementRead,
  hasOrganigramManagementEdit: (state: AuthState) => state.hasOrganigramManagementEdit,

  hasKeyContactsManagementRead: (state: AuthState) => state.hasKeyContactsManagementRead,
  hasKeyContactsManagementEdit: (state: AuthState) => state.hasKeyContactsManagementEdit,

  hasImportManagementRead: (state: AuthState) => state.hasImportManagementRead,
  hasImportManagementEdit: (state: AuthState) => state.hasImportManagementEdit,

  // tenant settings
  hasRolesManagementRead: (state: AuthState) => state.hasRolesManagementRead,
  hasRolesManagementEdit: (state: AuthState) => state.hasRolesManagementEdit,

  hasUsersManagementRead: (state: AuthState) => state.hasUsersManagementRead,
  hasUsersManagementEdit: (state: AuthState) => state.hasUsersManagementEdit,

  hasPermissionsManagementRead: (state: AuthState) => state.hasPermissionsManagementRead,

  hasDemoManagement: (state: AuthState) => state.hasDemoManagement
}

const { read } = getStoreAccessors<AuthState, RootState>('')

export const readHasProjectManagementPermissionRead = read(getters.hasProjectManagementPermissionRead)
export const readHasProjectManagementPermissionEdit = read(getters.hasProjectManagementPermissionEdit)

export const readHasBoardManagementRead = read(getters.hasBoardManagementRead)
export const readHasBoardManagementEdit = read(getters.hasBoardManagementEdit)

// system settings
export const readHasProjectTemplatesManagementRead = read(getters.hasProjectTemplatesManagementRead)
export const readHasProjectTemplatesManagementEdit = read(getters.hasProjectTemplatesManagementEdit)

export const readHasHeadquartersManagementRead = read(getters.hasHeadquartersManagementRead)
export const readHasHeadquartersManagementEdit = read(getters.hasHeadquartersManagementEdit)

export const readHasCustomersManagementRead = read(getters.hasCustomersManagementRead)
export const readHasCustomersManagementEdit = read(getters.hasCustomersManagementEdit)

export const readHasSuppliersManagementRead = read(getters.hasSuppliersManagementRead)
export const readHasSuppliersManagementEdit = read(getters.hasSuppliersManagementEdit)

export const readHasProductsManagementRead = read(getters.hasProductsManagementRead)
export const readHasProductsManagementEdit = read(getters.hasProductsManagementEdit)

export const readHasCalendarManagementRead = read(getters.hasCalendarManagementRead)
export const readHasCalendarManagementEdit = read(getters.hasCalendarManagementEdit)

export const readHasResourcesManagementRead = read(getters.hasResourcesManagementRead)
export const readHasResourcesManagementEdit = read(getters.hasResourcesManagementEdit)

export const readHasOrganigramManagementRead = read(getters.hasOrganigramManagementRead)
export const readHasOrganigramManagementEdit = read(getters.hasOrganigramManagementEdit)

export const readHasKeyContactsManagementRead = read(getters.hasKeyContactsManagementRead)
export const readHasKeyContactsManagementEdit = read(getters.hasKeyContactsManagementEdit)

export const readHasImportManagementRead = read(getters.hasImportManagementRead)
export const readHasImportManagementEdit = read(getters.hasImportManagementEdit)

// tenant settings
export const readHasRolesManagementRead = read(getters.hasRolesManagementRead)
export const readHasRolesManagementEdit = read(getters.hasRolesManagementEdit)

export const readHasUsersManagementRead = read(getters.hasUsersManagementRead)
export const readHasUsersManagementEdit = read(getters.hasUsersManagementEdit)

export const readHasPermissionsManagementRead = read(getters.hasPermissionsManagementRead)

export const readHasDemoManagement = read(getters.hasDemoManagement)
