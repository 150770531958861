import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { CompanyMachineState } from './state'

export const getters = {
  companyMachines: (state: CompanyMachineState) => state.companyMachines
}

const { read } = getStoreAccessors<CompanyMachineState, RootState>('')

export const readCompanyMachines = read(getters.companyMachines)
