import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { OfferingState } from './state'
import { IOffering } from '../types'

export const mutations = {
  setOfferingsCurrentPage (state: OfferingState, payload: IPaginatedResult<IOffering>) {
    state.offeringsCurrentPage = payload.data
    state.offeringsCount = payload.total
  }
}

const { commit } = getStoreAccessors<OfferingState | any, RootState>('')
export const commitSetOfferingsCurrentPage = commit(mutations.setOfferingsCurrentPage)
