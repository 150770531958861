import repository from '@/repositories/appAxios'
import { composePaginationQuery } from 'kwik-vue/utils/urlQuery'
import { IBatchesForProductionGetInput, IProductionOrderCreatePayload, IProductionOrdersGetInput } from '../types'

const resourceProductionOrders = '/company/production_orders'
const resourceProductionOrdersRows = '/company/production_orders'

const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: IProductionOrdersGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    if (getInput.status) filterQuery += `&status=${encodeURIComponent(getInput.status)}`
    if (getInput.materialId) filterQuery += `&material_id=${encodeURIComponent(getInput.materialId)}`
    if (getInput.machineId) filterQuery += `&machine_id=${encodeURIComponent(getInput.machineId)}`
    return repository.get(`${resourceProductionOrders}?${pagingQuery}${filterQuery}`, config)
  },
  getBatchesByProductionOrdersId (productionOrdersId: number) {
    return repository.get(`/company/production_order_batches/${productionOrdersId}`, config)
  },
  getBatchesForProduction (payload: IBatchesForProductionGetInput) {
    return repository.get(`/company/batches_for_production?material_id=${payload.material_id}`, config)
  },
  insertProductionOrder (payload: IProductionOrderCreatePayload) {
    return repository.post('/company/production_order', payload, config)
  },
  cancelProductionOrder (productionOrderId: number) {
    return repository.post(`/company/cancel_production_order?production_order_id=${productionOrderId}`, {}, config)
  },
  getAllForTimeline () {
    return repository.get('/company/production_orders_timeline', config)
  },
  getProductionOrderDuration (payload: IProductionOrderCreatePayload) {
    debugger
    let filterQuery = ''
    if (payload.start_clock) filterQuery += `start_clock=${payload.start_clock}`
    if (payload.material_id) filterQuery += `&material_id=${encodeURIComponent(payload.material_id)}`
    if (payload.company_shop_id) filterQuery += `&company_shop_id=${encodeURIComponent(payload.company_shop_id)}`
    if (payload.company_shop_machine_id) filterQuery += `&company_shop_machine_id=${encodeURIComponent(payload.company_shop_machine_id)}`
    if (payload.cleaning_id) filterQuery += `&cleaning_id=${encodeURIComponent(payload.cleaning_id)}`
    if (payload.forming_setup_id) filterQuery += `&forming_setup_id=${encodeURIComponent(payload.forming_setup_id)}`
    if (payload.packing_setup_id) filterQuery += `&packing_setup_id=${encodeURIComponent(payload.packing_setup_id)}`

    return repository.get(`/company/production_orders_duration?${filterQuery}`, config)
  }
}
