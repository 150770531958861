import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { BankingState } from './state'
import { IBanking } from '../types'

export const mutations = {
  setBankingCurrentPage (state: BankingState, payload: IPaginatedResult<IBanking>) {
    state.bankingCurrentPage = payload.data
    state.bankingCount = payload.total
  }
}

const { commit } = getStoreAccessors<BankingState | any, RootState>('')
export const commitSetBankingCurrentPage = commit(mutations.setBankingCurrentPage)
