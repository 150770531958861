import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { ProductionOrdersState } from './state'

const defaultState: ProductionOrdersState = {
  productionOrdersCount: 0,
  productionOrdersCurrentPage: []
}

export const productionOrdersModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
