import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { TrackingNoteState } from './state'

export const getters = {
  trackingNotesCurrentPage: (state: TrackingNoteState) => state.trackingNotesCurrentPage,
  trackingNotesCount: (state: TrackingNoteState) => state.trackingNotesCount
}

const { read } = getStoreAccessors<TrackingNoteState, RootState>('')

export const readTrackingNotesCurrentPage = read(getters.trackingNotesCurrentPage)
export const readTrackingNotesCount = read(getters.trackingNotesCount)
