import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { OfferingState } from './state'

const defaultState: OfferingState = {
  offeringsCount: 0,
  offeringsCurrentPage: []
}

export const offeringsModule = {
  namespaced: false,
  state: defaultState,
  mutations,
  actions,
  getters
}
