import repository from '@/repositories/appAxios'
import { composePaginationQuery } from '@/utils/pagination'
import { IBatchesGetInput } from '../types'

const resource = '/company/batches'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: IBatchesGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    if (getInput.material_id) filterQuery += `&material_id=${encodeURIComponent(getInput.material_id)}`
    if (getInput.code) filterQuery += `&code=${encodeURIComponent(getInput.code)}`
    return repository.get(`${resource}?${pagingQuery}${filterQuery}`, config)
  },
  disposeBatch (batchId: number) {
    return repository.put(`/company/disposal?batch_id=${batchId}`, {}, config)
  },
  returnBatch (batchId: number) {
    return repository.put(`/company/return?batch_id=${batchId}`, {}, config)
  }
}
