import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { CompanyMachineState } from './state'
import { ICompanyMachine } from '../types'

export const mutations = {
  setCompanyMachines (state: CompanyMachineState, payload: ICompanyMachine[]) {
    state.companyMachines = payload
  }
}

const { commit } = getStoreAccessors<CompanyMachineState | any, RootState>('')
export const commitSetCompanyMachines = commit(mutations.setCompanyMachines)
