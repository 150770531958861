import { getStoreAccessors } from 'typesafe-vuex'
import { RootState } from '@/store'
import { IPaginatedResult } from 'kwik-vue'
import { TrackingNoteState } from './state'
import { ITrackingNote } from '../types'

export const mutations = {
  setTrackingNotesCurrentPage (state: TrackingNoteState, payload: IPaginatedResult<ITrackingNote>) {
    state.trackingNotesCurrentPage = payload.data
    state.trackingNotesCount = payload.total
  }
}

const { commit } = getStoreAccessors<TrackingNoteState | any, RootState>('')
export const commitSetTrackingNotesCurrentPage = commit(mutations.setTrackingNotesCurrentPage)
